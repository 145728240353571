// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useUserContext } from "Authenticator";
import { fetcherWithToken, updateWithToken } from "hooks/use-fetch-data";
import useSWR from "swr";
import {
  ClassBar,
  ClassSection,
  Container,
  FlexedDiv,
  RightSide,
} from "./styles";
import { Text } from "components/Text";
import { TeacherData, Class, UserModule, Test, School } from "./types";
import { MdOutlineNotStarted } from "react-icons/md";
import { useTimer } from "hooks/timer";
import { ToggleSwitch } from "components/Toggle";
import toast from "react-hot-toast";
import { FaRegCheckCircle } from "react-icons/fa";
import { secondsToMinutesSeconds } from "Pages/helpers";
import { useNavigate } from "react-router-dom";

const Teacher = () => {
  const userCtx = useUserContext();
  const navigate = useNavigate();
  const [allSchools, setAllSchools] = useState<School[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<School>();
  const [selectedClass, setSelectedClass] = useState<Class>();
  const [selectedModule, setSelectedModule] = useState<UserModule>();

  const moduleTimeUp = async () => {
    const res = await updateWithToken(
      `/api/v1/auth/class/${selectedClass?.ClassID}/test/${selectedClass?.Tests[0].TestID}/module/${selectedModule?.ModuleID}/module-finished`,
      userCtx.token,
      {
        moduleID: Number(selectedModule?.ModuleID),
        classID: Number(selectedModule?.ClassID)
      }
    );

    if (res.status === 201) {
      setSelectedClass(prevSelectedClass => {
        if (!prevSelectedClass) {
          return prevSelectedClass;
        }

        const updatedTests = prevSelectedClass.Tests.map(test => {
          const updatedModules = test.Modules.map(module => {
            if (module.ModuleID === selectedModule.ModuleID) {
              if (
                module.UserModule.UserModuleID === selectedModule.UserModuleID
              ) {
                module.UserModule.Finished = true;
              }
            }
            return module;
          });

          return { ...test, Modules: updatedModules };
        });

        return { ...prevSelectedClass, Tests: updatedTests };
      });
    }
  };

  const { minutes, seconds, start } = useTimer({
    onFinish: moduleTimeUp
  });

  const { data, error, mutate } = useSWR<TeacherData>(
    `/api/v1/auth/teacher/dashboard`,
    (url: string) => fetcherWithToken<TeacherData>(url, userCtx.token)
  );

  if (error) {
    console.log("error", error);
  }

  useEffect(() => {
    if (data?.schools) {
      setAllSchools(data.schools);

      if (selectedSchool) {
        const foundSchool = data?.schools?.find(
          s => s.SchoolID === selectedSchool.SchoolID
        );
        setSelectedSchool(foundSchool);

        if (selectedClass) {
          const foundClass = foundSchool?.Classes.find(
            c => c.ClassID === selectedClass?.ClassID
          );
          setSelectedClass(foundClass);
        }
      } else {
        setSelectedSchool(data.schools?.[0]);
        setSelectedClass(data.schools?.[0].Classes?.[0]);
      }
    }
  }, [data?.schools]);

  const handleSchoolChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const foundSchool = allSchools?.find(s => s.Name === event.target.value);

    setSelectedSchool(foundSchool);
    setSelectedClass(foundSchool.Classes[0]);
  };

  const handleClassChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const foundClass = selectedSchool?.Classes?.find(
      c => c.Name === event.target.value
    );

    setSelectedClass(foundClass);
  };

  const startModule = async (um: UserModule, testID: number) => {
    const res = await updateWithToken(
      `/api/v1/auth/class/${selectedClass?.ClassID}/test/${testID}/module/${um.ModuleID}/module-started`,
      userCtx.token,
      { moduleID: um.ModuleID }
    );

    if (res.status === 201) {
      setSelectedClass(prevSelectedClass => {
        if (!prevSelectedClass) {
          return prevSelectedClass;
        }

        const updatedTests = prevSelectedClass.Tests.map(test => {
          const updatedModules = test.Modules.map(module => {
            if (module.ModuleID === um.ModuleID) {
              if (module.UserModule.UserModuleID === um.UserModuleID) {
                module.UserModule.Started = true;
              }
            }
            return module;
          });

          return { ...test, Modules: updatedModules };
        });

        return { ...prevSelectedClass, Tests: updatedTests };
      });

      setSelectedModule(um);
      start(um.TimeLeft);
    }
  };

  const updateTimeMode = async (test: Test) => {
    const res = await updateWithToken(
      `/api/v1/auth/teacher/update-untimed-mode`,
      userCtx.token,
      {
        classID: selectedClass?.ClassID,
        testID: test.TestID,
        untimedMode: !test.UntimedMode
      }
    );
    if (res.status === 201) {
      await mutate(data);

      toast.success("Updated time mode", {
        position: "bottom-left",
        icon: <FaRegCheckCircle size={18} color="#154471" />
      });
    }
  };

  const reportLinks = (studentEmail: string) => {
    return selectedClass?.Tests.map(test => (
      <>
        <Text
          hover
          onClick={() =>
            navigate(
              `/class/${selectedClass.ClassID}/test/${test.TestID}/report`,
              { state: { student: studentEmail } }
            )
          }
        >
          {test.Title}
        </Text>
      </>
    ));
  };
  return (
    <Container>
      <RightSide>
        <ClassBar>
          <Text size={18} color="white">
            School: {selectedSchool?.Name}
          </Text>
          <select onChange={handleSchoolChange} value={selectedSchool?.Name}>
            {allSchools?.map(s => {
              return <option value={s.Name}>{s.Name}</option>;
            })}
          </select>
        </ClassBar>
        <ClassBar>
          <Text size={18} color="white">
            Class: {selectedClass?.Name}
          </Text>
          <select onChange={handleClassChange} value={selectedClass?.Name}>
            {selectedSchool?.Classes?.map(c => {
              return <option value={c.Name}>{c.Name}</option>;
            })}
          </select>
        </ClassBar>
        <ClassSection>
          {selectedClass?.Tests?.map(test => (
            <>
              <Text bold size={24} mb={12}>
                Test: {test?.Title}
              </Text>
              <FlexedDiv>
                <Text size={16}>
                  {minutes} mins : {seconds} sec
                </Text>
                <ToggleSwitch
                  checked={test.UntimedMode}
                  onChange={() => updateTimeMode(test)}
                  withText="Untimed Mode"
                />
              </FlexedDiv>
              <table>
                <thead>
                  <tr>
                    <th colSpan="6">Modules</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Module Order</th>
                    <th>Module Name</th>
                    <th>Module Difficulty</th>
                    <th>Time</th>
                    <th>Has Started?</th>
                    <th>Start</th>
                  </tr>
                </thead>
                <tbody>
                  {test?.Modules?.map(row => (
                    <tr>
                      <td>{row.Order}</td>
                      <td>{row.Title}</td>
                      <td>{row.Level}</td>
                      <td>{secondsToMinutesSeconds(row.Time)}</td>
                      <td>{row.UserModule.Started.toString()}</td>
                      <td>
                        <MdOutlineNotStarted
                          onClick={() =>
                            startModule(row.UserModule, row.TestID)
                          }
                          size={20}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ))}
          <table>
            <thead>
              <tr>
                <th colSpan="3">Students</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>View Report</th>
              </tr>
            </thead>
            <tbody>
              {selectedClass?.Students?.map(row => (
                <tr>
                  <td>
                    {row.FirstName} {row.LastName}
                  </td>
                  <td>{row.Email}</td>
                  <td>{reportLinks(row.Email)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ClassSection>
      </RightSide>
    </Container>
  );
};

export default Teacher;
