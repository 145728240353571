import React, { useState } from "react";
import { Container, FieldContainer, Label, Select } from "./styles";
import { updateWithToken } from "../../../hooks/use-fetch-data";
import { useUserContext } from "../../../Authenticator";
import { Button, Text } from "components";
import { useParams } from "react-router-dom";
import { KeyedMutator } from "swr";
import { TestI } from "../types";

export const Accommodations: React.FC<{
  refetch: KeyedMutator<TestI>;
  close: () => void;
}> = ({ refetch, close }) => {
  const params = useParams();
  const userCtx = useUserContext();
  const [readingTime, setReadingTime] = useState<number>(0);
  const [mathTime, setMathTime] = useState<number>(0);


  const updateAccomidations = async () => {
    const res = await updateWithToken(
      `/api/v1/auth/class/${params.classID}/test/${params.testID}/extra-time`,
      userCtx.token,
      {
        classID: Number(params.classID),
        testID: Number(params.testID),
        math: mathTime,
        english: readingTime
      }
    );

    if (res.status === 201) {
      await refetch();
      close();
    }
  };

  return (
    <Container>
      <Text size={24} bold center>
        Accommodations and Support
      </Text>
      <FieldContainer>
        <Label>Reading: Extended Time</Label>
        <Select
          value={readingTime}
          onChange={e => setReadingTime(Number(e.target.value))}
        >
          <option value={0}>No extra time</option>
          <option value={50}>Time and one half - 50% Extra Time</option>
          <option value={100}>Double - 100% Extra Time</option>
        </Select>
      </FieldContainer>

      <FieldContainer>
        <Label>Math: Extended Time</Label>
        <Select
          value={mathTime}
          onChange={e => setMathTime(Number(e.target.value))}
        >
          <option value={0}>No extra time</option>
          <option value={50}>Time and one half - 50% Extra Time</option>
          <option value={100}>Double - 100% Extra Time</option>
        </Select>
      </FieldContainer>

      <Button
        text="Save"
        onClick={updateAccomidations}
      />
    </Container>
  );
};
