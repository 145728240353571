import React, { useEffect, useState } from "react";
import { useUserContext } from "Authenticator";
import { Container, LeftSide, Tile, SchoolBar, RightSide } from "./styles";
import { Text } from "components/Text";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdOutlinePeopleOutline } from "react-icons/md";
import { GiTeacher } from "react-icons/gi";
import { fetcherWithToken } from "hooks/use-fetch-data";
import { AdminDash, Class, School, Student, Teacher, Test } from "./types";
import { FaSchool } from "react-icons/fa6";
import { ClassComponent } from "./Class/index";
import { SchoolOnboard } from "./SchoolOnboard/index";
import { Students } from "./Students";
import { Teachers } from "./Teachers";
import { MdOutlinePreview } from "react-icons/md";
import { PreviewTest } from "./PreviewTest";

const Admin = () => {
  const userCtx = useUserContext();

  const [selectedTab, setSelectedTab] = useState("class");
  const [selectedSchool, setSelectedSchool] = useState<School>();
  const [allSchools, setAllSchools] = useState<School[]>();
  const [allStudents, setAllStudents] = useState<Student[]>();
  const [allTeachers, setAllTeachers] = useState<Teacher[]>();
  const [allClasses, setAllClasses] = useState<Class[]>();
  const [allTests, setAllTests] = useState<Test[]>();


  useEffect(() => {
    const fetch = async () => {
      const resp = await fetcherWithToken<AdminDash>(
        `/api/v1/auth/admin/dashboard`,
        userCtx.token
      );

      setAllClasses(resp.classes || []);
      setAllSchools(resp.schools || []);
      setSelectedSchool(resp.selectedSchool);
      setAllStudents(resp.allStudents|| []);
      setAllTeachers(resp.teachers || []);
      setAllTests(resp.tests || []);
    };

    fetch();
  }, []);

  const refetchDashboard = async (school?: School) => {
    const resp = await fetcherWithToken<AdminDash>(
      school
        ? `/api/v1/auth/admin/dashboard?schoolSlug=${school.Slug}`
        : `/api/v1/auth/admin/dashboard`,
      userCtx.token
    );

    console.log("refetchDashboard", resp)
    setSelectedSchool(resp.selectedSchool);
    setAllStudents(resp.allStudents);
    setAllTeachers(resp.teachers);
    setAllClasses(resp.classes);
    setAllTests(resp.tests);
    setAllSchools(resp.schools);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const school = allSchools?.find(s => s.Slug === event.target.value);

    await refetchDashboard(school);
  };

  return (
    <Container>
      <LeftSide>
        <Tile
          active={selectedTab === "class"}
          onClick={() => setSelectedTab("class")}
        >
          <GiTeacher size={23} />
          <Text size={18}>Classes</Text>
        </Tile>
        <Tile
          active={selectedTab === "students"}
          onClick={() => setSelectedTab("students")}
        >
          <MdOutlinePeopleOutline size={23} />
          <Text size={18}>Students</Text>
        </Tile>
        <Tile
          active={selectedTab === "teachers"}
          onClick={() => setSelectedTab("teachers")}
        >
          <FaPeopleGroup />

          <Text size={18}>Teachers</Text>
        </Tile>
        <Tile
          active={selectedTab === "school"}
          onClick={() => setSelectedTab("school")}
        >
          <FaSchool size={23} />
          <Text size={14}>Onboard a school</Text>
        </Tile>
        <Tile
          active={selectedTab === "tests"}
          onClick={() => setSelectedTab("tests")}
        >
          <MdOutlinePreview size={23} />
          <Text size={18}>Preview Tests</Text>
        </Tile>
      </LeftSide>
      <RightSide>
        <SchoolBar>
          <Text size={18} color="white">
            School: {selectedSchool?.Name}
          </Text>
          <select onChange={handleChange} value={selectedSchool?.Slug}>
            {allSchools?.map(s => {
              return <option value={s.Slug}>{s.Name}</option>;
            })}
          </select>
        </SchoolBar>
        {selectedTab === "class" && (
          <ClassComponent
            classes={allClasses}
            students={allStudents}
            teachers={allTeachers}
            tests={allTests}
            selectedSchool={selectedSchool}
            refreshDashboard={refetchDashboard}
          />
        )}
        {selectedTab === "students" && (
          <Students
            students={!!allStudents ? allStudents : undefined}
            school={selectedSchool}
            refreshDashboard={refetchDashboard}
          />
        )}
        {selectedTab === "teachers" && (
          <Teachers
            teachers={!!allTeachers ? allTeachers : undefined}
            school={selectedSchool}
            refreshDashboard={refetchDashboard}
          />
        )}
        {selectedTab === "school" && (
          <SchoolOnboard
            refetchDashboard={refetchDashboard}
            setHomeTab={() => setSelectedTab("class")}
          />
        )}
        {selectedTab === "tests" && (
          <div>
            <PreviewTest />
          </div>
        )}
      </RightSide>
    </Container>
  );
};

export default Admin;
