import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { PublicRoutes } from "routes";
import { GETTING_STARTED } from "routes/constants";
import { ThemeProvider } from "styled-components";
import { theme } from "components/theme/theme";
import { fetcherWithToken } from "hooks/use-fetch-data";

interface User {
  Email: string;
  Role: string;
}

export const UserContext = createContext<any>({});

export const TokenProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const location = useLocation();

  const [token, setToken] = useState<string>("");
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (isAuthenticated) {
      const fetchToken = async () => {
        const token = await getAccessTokenSilently();

        return token;
      };

      const fetchCurrentUser = async (token: string) => {
        const currentUser = await fetcherWithToken<User>(
          `/api/v1/auth/current-user`,
          token
        );

        setUser(currentUser);
      };

      fetchToken()
        .then((t) => {
          fetchCurrentUser(t);
          setToken(t);
        })
        .catch((e) => console.log("TOKEN ERROR", e));
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading) return null;

  if (location.pathname === GETTING_STARTED) {
    return (
      <ThemeProvider theme={theme}>
        <PublicRoutes />
      </ThemeProvider>
    );
  }

  if (!isAuthenticated) {
      loginWithRedirect()
  }

  if ((isAuthenticated && token === "") || (isAuthenticated && token === null))
    return null;

  return (
    <UserContext.Provider value={{ token, user }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

interface KaTeXContextProps {
  renderMath?: () => void;
}

const KaTeXContext = createContext<KaTeXContextProps>({});


export const useKaTeX = () => {
  return useContext(KaTeXContext);
};

export const KaTeXProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
   const containerRef = React.useRef<HTMLDivElement | null>(null);


    const renderMath = React.useCallback(() => {
    if (window.renderMathInElement && containerRef.current) {
      window.renderMathInElement(containerRef.current, {
        delimiters: [
          { left: "\\(", right: "\\)", display: false },
          { left: "\\[", right: "\\]", display: true }
        ]
      });
    }
  }, []);

  useEffect(() => {
    renderMath();
  }, [renderMath]);

  return (
    <KaTeXContext.Provider value={{ renderMath }}>
          <div ref={containerRef}>
      {children}
          </div>
    </KaTeXContext.Provider>
  );
};


export const useKatexContext = () => useContext(KaTeXContext)
