import React from 'react';
import { Props } from './types';
import { TextComponent } from './styles';

export const Text: React.FC<Props> = ({
  size,
  bold,
  children,
  mb,
  mt,
  ml,
  mr,
  className,
  color,
  center,
  onClick,
  background,
  hover,
  underline
}) => {
  return (
    <TextComponent
      onClick={onClick}
      color={color}
      bold={bold}
      size={size || 12}
      mb={mb}
      center={center}
      className={className}
      mt={mt}
      ml={ml}
      mr={mr}
      background={background}
      hover={hover}
      underline={underline}
    >
      {children}
    </TextComponent>
  );
};
