import styled from "styled-components";

export const SectionContainer = styled.div`
  margin: auto;
  border-radius: 10px;
  max-width: 1080px;
  margin-top: 24px;
  background: ${({ theme }) => theme.colors.secondary1};
  padding: 48px;

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    svg {
      cursor: pointer;
    }
  }

  thead tr {
    background: ${({ theme }) => theme.colors.primary1};
    color: #ffffff;
    text-align: left;
  }

  th,
  td {
    padding: 12px 15px;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  tbody tr:last-of-type {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary1}`};
  }
`;

export const ClassHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary1}`};
  margin-bottom: 24px;
  padding-bottom: 12px;
`;

export const StepContainer = styled.div`
  margin-top: 80px;
`;

export const UpdateClassContainer = styled.div`
  margin: 10px 0;
  justify-content: space-between;
`;
