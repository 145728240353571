import React from "react";
import { Text } from "components/Text";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  ResponsiveContainer
} from "recharts";
import { GroupedData, DomainData } from "./utils";
const COLORS = ["#FBB23B", "#154471", "#696969"];

const renderCustomizedLabel = (props: any) => {
  const RADIAN = Math.PI / 180;
  const radius =
    25 + props.innerRadius + (props.outerRadius - props.innerRadius);
  const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
  const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill={COLORS[props.index % COLORS.length]}
      textAnchor={x > props.cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {props.name}
    </text>
  );
};

export const Analysis: React.FC<{
  levelData: GroupedData[];
  domainData: DomainData[];
}> = ({ levelData, domainData }) => {
  const valueAccessor = (attribute: any) => ({ payload }: any) => {
    return payload.data[attribute];
  };

  return (
    <>
      <Text bold size={28} mb={24}>
        How you did, by difficulty
      </Text>
        <div style={{ width: '100%', height: 300, display: 'flex' }}>
        {levelData.map(d => {
          const level = Object.keys(d)[0];
          const dt = [
            { name: `Correct ${d[level].right}`, value: d[level].right },
            { name: `Incorrect ${d[level].wrong}`, value: d[level].wrong },
            {
              name: `Unanswered ${d[level].unanswered}`,
              value: d[level].unanswered
            }
          ];
          return (
            <ResponsiveContainer>
              <PieChart>
                <text
                  x={115}
                  y={125}
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  {level}
                </text>
                <Pie
                  data={dt}
                  cx={110}
                  cy={120}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  label={renderCustomizedLabel}
                  dataKey="value"
                >
                  {dt.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      style={{ outline: "none" }}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          );
        })}
        </div>
      <Text bold size={28} mb={24}>
        How you did, by content domain
      </Text>
      <BarChart data={domainData} width={900} height={300} layout="vertical">
        <XAxis type="number" hide />
        <YAxis type="category" dataKey={"domain"} width={100} />
        <Bar dataKey="data.right" fill={"#FBB23B"} stackId={1}>
          <LabelList valueAccessor={valueAccessor("right")} />
        </Bar>
        <Bar dataKey="data.wrong" fill={"#154471"} stackId={1}>
          <LabelList valueAccessor={valueAccessor("wrong")} />
        </Bar>
        <Bar dataKey="data.unanswered" fill={"#696969"} stackId={1}>
          <LabelList valueAccessor={valueAccessor("unanswered")} />
        </Bar>
        <Legend
          payload={[
            { value: "Correct", id: "right", color: "#FBB23B" },
            { value: "Incorrect", id: "wrong", color: "#154471" },
            { value: "Unanswered", id: "unanswered", color: "#696969" }
          ]}
        />
      </BarChart>
    </>
  );
};
