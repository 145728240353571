// MathSkillsStyles.js
import styled from "styled-components";

export const Container = styled.div`
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
`;

export const Heading = styled.h1`
  font-size: 1.5em;
  color: #333;
`;

export const SubHeading = styled.h2`
  font-size: 1.2em;
  margin-top: 20px;
  color: #444;
`;

export const Paragraph = styled.p`
  margin: 2px 0;
`;

export const List = styled.ul`
  margin: 5px 0;
  padding-left: 20px;
`;

export const ListItem = styled.li`
  margin-bottom: 5px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
`;

export const TableHead = styled.th`
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  background-color: #f2f2f2;
`;

export const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
`;

export const ExamplesSection = styled.div`
  margin-top: 10px;
`;

