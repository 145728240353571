
import React, { useState } from "react";
import {
  SectionContainer,
  StepContainer,
  InputContainer,
  IconContainer
} from "../styles";
import { Step, Stepper } from "components/Stepper";
import { Input } from "components/Input";
import { School } from "../types";
import { Text } from "components/Text";
import { FiUserMinus } from "react-icons/fi";
import { LuUserPlus2 } from "react-icons/lu";
import { postWithToken } from "hooks/use-fetch-data";
import { useUserContext } from "Authenticator";
import { NewStudent } from "./types";


const steps = [
  {
    label: "Name School",
    step: 1
  },
  {
    label: "Create Teachers",
    step: 2
  },
  {
    label: "Create Students",
    step: 3
  }
];
export const SchoolOnboard: React.FC<{
  refetchDashboard: (s?: School) => void;
  setHomeTab: () => void;
}> = ({ refetchDashboard, setHomeTab }) => {
  const userCtx = useUserContext();

  const [formData, setFormData] = useState<{
    schoolName: string;
    students: NewStudent[];
    teachers: NewStudent[];
  }>({
    schoolName: "",
    students: [{ 1: { FirstName: "", LastName: "", Email: "" } }],
    teachers: [{ 1: { FirstName: "", LastName: "", Email: "" } }]
  });

  const schoolChnage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, schoolName: e.target.value });
  };

  const teacherChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
    index: number
  ) => {
    const updatedTeachers = formData.teachers.map(student => {
      const studentIndex = Number(Object.keys(student)[0]);

      if (studentIndex === index) {
        return {
          [index]: {
            ...student[index],
            [field]: e.target.value
          }
        };
      }

      return student;
    });

    setFormData({
      ...formData,
      teachers: updatedTeachers
    });
  };

  const studentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
    index: number
  ) => {
    const updateStudents = formData.students.map(student => {
      const studentIndex = Number(Object.keys(student)[0]);

      if (studentIndex === index) {
        return {
          [index]: {
            ...student[index],
            [field]: e.target.value
          }
        };
      }

      return student;
    });

    setFormData({
      ...formData,
      students: updateStudents
    });
  };

  const addAnotherTeacher = () => {
    const next =
      Number(Object.keys(formData.teachers[formData.teachers.length - 1])[0]) +
      1;

    setFormData({
      ...formData,
      teachers: [
        ...formData.teachers,
        { [next]: { FirstName: "", LastName: "", Email: "" } }
      ]
    });
  };

  const addAnotherStudent = () => {
    const next =
      Number(Object.keys(formData.students[formData.students.length - 1])[0]) +
      1;

    setFormData({
      ...formData,
      students: [
        ...formData.students,
        { [next]: { FirstName: "", LastName: "", Email: "" } }
      ]
    });
  };

  const minusTeacher = () => {
    const allButLast = formData.teachers.slice(0, -1);

    setFormData({
      ...formData,
      teachers: [...allButLast]
    });
  };

  const minusStudent = () => {
    const allButLast = formData.students.slice(0, -1);

    setFormData({
      ...formData,
      students: [...allButLast]
    });
  };

  const submitData = async () => {
    const students = formData.students.map(s => {
      const key = Number(Object.keys(s)[0]);
      return s[key];
    });
    const teachers = formData.teachers.map(s => {
      const key = Number(Object.keys(s)[0]);
      return s[key];
    });

    const data = {
      students,
      teachers,
      schoolName: formData.schoolName
    };

    const res = await postWithToken(
      `/api/v1/auth/admin/dashboard/onboard-school`,
      userCtx.token,
      { ...data }
    );

    if (res.status === 201) {
      setHomeTab();
      refetchDashboard();
    }
  };

  return (
    <SectionContainer>
      <Stepper steps={steps}>
        <Step s={1}>
          <StepContainer>
            <Text size={14} bold mb={12}>
              Create a School
            </Text>
            <Input
              width={400}
              placeholder="School Name..."
              onChange={schoolChnage}
              value={formData.schoolName}
            />
          </StepContainer>
        </Step>
        <Step s={2}>
          <StepContainer>
            <Text size={14} bold mb={12}>
              Create Teachers for {formData.schoolName}
            </Text>
            {formData.teachers.map(teacher => {
              const key = Number(Object.keys(teacher)[0]);
              return (
                <InputContainer key={key}>
                  <Input
                    width={250}
                    placeholder="First Name..."
                    value={teacher[key].FirstName}
                    onChange={e => teacherChange(e, "FirstName", key)}
                  />
                  <Input
                    width={250}
                    placeholder="Last Name..."
                    value={teacher[key].LastName}
                    onChange={e => teacherChange(e, "LastName", key)}
                  />
                  <Input
                    width={300}
                    placeholder="Email..."
                    value={teacher[key].Email}
                    onChange={e => teacherChange(e, "Email", key)}
                  />
                </InputContainer>
              );
            })}
            <IconContainer>
              <span>
                <FiUserMinus size={30} onClick={minusTeacher} />
              </span>
              <span onClick={addAnotherTeacher}>
                <LuUserPlus2 size={30} />
              </span>
            </IconContainer>
          </StepContainer>
        </Step>
        <Step s={3} next={() => submitData()}>
          <StepContainer>
            <Text size={14} bold mb={12}>
              Create Students for {formData.schoolName}
            </Text>
            {formData.students.map(student => {
              const key = Number(Object.keys(student)[0]);
              return (
                <InputContainer key={key}>
                  <Input
                    width={250}
                    placeholder="First Name..."
                    value={student[key].FirstName}
                    onChange={e => studentChange(e, "FirstName", key)}
                  />
                  <Input
                    width={250}
                    placeholder="Last Name..."
                    value={student[key].LastName}
                    onChange={e => studentChange(e, "LastName", key)}
                  />
                  <Input
                    width={300}
                    placeholder="Email..."
                    value={student[key].Email}
                    onChange={e => studentChange(e, "Email", key)}
                  />
                </InputContainer>
              );
            })}
            <IconContainer>
              <span>
                <FiUserMinus size={30} onClick={minusStudent} />
              </span>
              <span onClick={addAnotherStudent}>
                <LuUserPlus2 size={30} />
              </span>
            </IconContainer>
          </StepContainer>
        </Step>
      </Stepper>
    </SectionContainer>
  );
};
