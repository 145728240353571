import React, { useState } from "react";
import { useUserContext } from "../../Authenticator";
import { fetcherWithToken, updateWithToken } from "../../hooks/use-fetch-data";
import { useNavigate, useParams } from "react-router-dom";
import { TestI } from "./types";
import {
  Container,
  Header,
  QuestionNumberBox,
  Body,
  RightRail,
  BodyContainer,
  InstructionLogo,
  BodyHeader,
  NumberBox,
  RightRailBox,
  Untimed
} from "./styles";
import { Button } from "components/Button";
import { Text } from "components/Text";
import useSWR from "swr";
import { MdOutlineTimerOff, MdOutlineTimer } from "react-icons/md";
import toast from "react-hot-toast";
import { FaRegCheckCircle } from "react-icons/fa";
import { theme } from "components/theme/theme";
import { Modal } from "components";
import { Accommodations } from "./Accommodations";
import { secondsToMinutesSeconds } from "Pages/helpers"


const TestIntro = () => {
  const userCtx = useUserContext();
  const params = useParams();
  const navigate = useNavigate();

  const [accomidationsmModal, setAccomidationsmModal] = useState(false);

  const { data, error, mutate } = useSWR<TestI>(
    `/api/v1/auth/class/${params.classID}/test/${params.testID}/modules`,
    (url: string) => fetcherWithToken<TestI>(url, userCtx.token)
  );

  if (error) {
    console.error("ERROR", error);
  }

  const module = data?.Modules.find(
    m => m.ModuleID.toString() === params.moduleId
  );

  const canStart = !module?.TeacherModule.Started;

  const startModule = async () => {
    const res = await updateWithToken(
      `/api/v1/auth/class/${params.classID}/test/${params.testID}/module/${params.moduleId}/module-started`,
      userCtx.token,
      { moduleID: module?.ModuleID }
    );

    if (res.status === 201) {
      navigate(`question/1`);
    }
  };

  const updatedTimerMode = async (mode: boolean) => {
    const res = await updateWithToken(
      `/api/v1/auth/class/${params.classID}/test/${params.testID}/module/${params.moduleId}/update-time-mode`,
      userCtx.token,
      { mode }
    );

    if (res.status === 201) {
      mutate(data);
      toast.success("Updated timer mode", {
        position: "bottom-left",
        icon: <FaRegCheckCircle size={18} color="#154471" />
      });
    }
  };

  return (
    <>
      <Modal
        open={accomidationsmModal}
        onClose={() => setAccomidationsmModal(false)}
        small
        width="20%"
      >
        <Accommodations
          refetch={mutate}
          close={() => setAccomidationsmModal(false)}
        />
      </Modal>
      <Container>
        <Header>
          <div>
            <Text mb={6} size={12} color="white">
              {data?.Title}
            </Text>
            <Text size={24} bold color="white">
              {module?.Title}
            </Text>
          </div>
          <Text
            size={16}
            hover
            underline
            color={theme.colors.primary1}
            onClick={() => setAccomidationsmModal(true)}
          >
            Need accommodations?
          </Text>
        </Header>
        <BodyContainer>
          <Body>
            <NumberBox>
              <QuestionNumberBox selected>Intro</QuestionNumberBox>
              {module?.Questions.map(q => (
                <QuestionNumberBox>{q.QuestionNumber}</QuestionNumberBox>
              ))}
            </NumberBox>
            <BodyHeader>
              <InstructionLogo src="https://curvebreakerstestprep.com/wp-content/uploads/2020/11/cropped-favicon-website-512x512-yellow-180x180.png"></InstructionLogo>
              <h2>Section Instructions</h2>
            </BodyHeader>
            {module?.Instructions && (
              <div
                dangerouslySetInnerHTML={{ __html: module.Instructions }}
              ></div>
            )}
          </Body>
          <RightRail>
            {data?.UntimedMode && module?.UserModule.UntimedMode ? (
              <RightRailBox>
                <Text bold size={18} mb={12}>
                  Untimed Mode
                </Text>
                <MdOutlineTimerOff size={34} />
              </RightRailBox>
            ) : (
              <RightRailBox>
                <Text size={24} mb={12} bold>
                  Remaining Time
                </Text>
                <Text size={18}>
                  {secondsToMinutesSeconds(module?.UserModule?.TimeLeft)}
                </Text>
              </RightRailBox>
            )}
            <Button
              disabled={canStart}
              onClick={startModule}
              text={
                module?.UserModule.Started ? "Continue Module" : "Start Module"
              }
              fullWidth
              mb={18}
            />
            {data?.UntimedMode && module?.UserModule.UntimedMode && (
              <Untimed>
                <Text size={14} mb={18}>
                  You are currented in untimed mode. Do you want to turn the
                  timer back on?
                </Text>
                <div onClick={() => updatedTimerMode(false)}>
                  <Text bold size={14}>
                    Start timer mode
                  </Text>
                  <MdOutlineTimer size={24} />
                </div>
              </Untimed>
            )}
            {data?.UntimedMode && !module?.UserModule.UntimedMode && (
              <Untimed>
                <Text size={14} mb={18}>
                  Want to take this section untimed? Take this section in
                  Untimed Mode to answer questions ar your own pace.
                </Text>
                <div onClick={() => updatedTimerMode(true)}>
                  <Text bold size={14}>
                    Start untimed mode &#8594;
                  </Text>
                  <MdOutlineTimerOff size={24} />
                </div>
              </Untimed>
            )}
          </RightRail>
        </BodyContainer>
      </Container>
    </>
  );
};

export default TestIntro;
