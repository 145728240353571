import {Button, DivComponent} from "components";
import { Text } from "components/Text";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 24px 24px 0px 24px;
  min-height: 90vh;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.secondary1};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: ${({ theme }) => theme.colors.primary2};
  color: ${({ theme }) => theme.colors.secondary1};
  margin-bottom: 10px;
`;

export const BodyContainer = styled.div`
  display: flex;
`;

export const Body = styled.div`
  padding: 24px;
  margin-bottom: 20px;
  transition: width 0.2s ease;
  width: 66%;
`;


export const BodyHeader = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin-left: 10px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.primary2};
`;

export const Question = styled.a`
  background: ${({ theme }) => theme.colors.primary1};
  padding: 10px;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const RightRail = styled.div`
  padding: 24px;
  padding: 20px;
  overflow: auto;
  width: 33%;
`;

export const EachAnswer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid black;
`;

export const QuestionBox = styled.div`
  background: ${({ theme }) => theme.colors.grey1};
  color: ${({ theme }) => theme.colors.primary2};
  padding: 8px;
  margin-bottom: 24px;
  border-radius: 10px;

  input {
    margin-bottom: 10px;
    margin: auto;
    height: 50px;
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }

  input[type="text"]:focus {
    border: ${({ theme }) => `3px solid ${theme.colors.primary1}`};
  }
`;

export const Answer = styled.span<{ crossed?: boolean; selected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 20px 0;
  size: 14px;
  color: black;
  margin-left: 10px;
  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.colors.primary1};
    cursor: pointer;
  }

  ${({ crossed }) =>
    crossed &&
    `
    text-decoration: line-through;
     & * {
      text-decoration: line-through;
    }
    `}

  ${({ selected, theme }) =>
    selected &&
    `
    font-weight: bold;
    color: ${theme.colors.primary1};
    `}
`;

export const X = styled(Text)`
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
`;

export const SaveLater = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 5px 0;
  text-align: center;
  border: 1px solid white;
  &:hover {
    border: 1px solid black;
    cursor: pointer;
  }

  && {
    margin-top: 20px;
  }

  svg {
    margin-right: 10px;
  }
`;

export const AroundTooltip = styled.div`
  .tooltip {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background: white;
    width: 500px;
  }

  .react-tooltip__show {
    opacity: 1;
  }
`;

export const UntimedMode = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.colors.primary1};
  }
`;

export const QuestionHtml = styled.div`
  p {
    font-size: 20px;
  }

  img {
    height: auto;
    max-width: 750px;
  }

  table,
  th,
  td {
    border: 1px solid;
    border-collapse: collapse;
    width: 200px;
    text-align: center;
  }
`;

export const AnswerHtml = styled.div`
  margin-left: 10px;
  table,
  th,
  td {
    border: 1px solid;
    border-collapse: collapse;
    width: 100px;
    text-align: center;
  }
  p {
    font-size: 20px;
  }
`;

export const FinishButton = styled(Button)`
  position: absolute;
  bottom: 20px;
  margin: auto;
  left: 0;
  right: 0;
  width: 200px;

`

export const MathButtons = styled(DivComponent)`
  div {
    margin-left: 24px;
    cursor: pointer;
  }
`
