
import { ColumnDef } from "@tanstack/react-table";
import { fuzzySort } from "components";

export const steps = [
  {
    label: "Class Name",
    step: 1
  },
  {
    label: "Assign Teacher",
    step: 2
  },
  {
    label: "Assign Students",
    step: 3
  },
  {
    label: "Assign Tests",
    step: 4
  }
];

export const dataColumnsTests: ColumnDef<any, any>[] = [
  {
    accessorFn: row => row.Title, // Accessor function for the title
    id: "TestID",
    header: "Title", // Header for the column
    cell: info => info.getValue(), // Cell renderer
    sortingFn: fuzzySort // Sorting function
  }
];

export const dataColumnsStudents: ColumnDef<any, any>[] = [
  {
    accessorFn: row => row.Email, // Accessor function for the title
    id: "Email",
    header: "Email", // Header for the column
    cell: info => info.getValue(), // Cell renderer
    sortingFn: fuzzySort // Sorting function
  },
  {
    accessorFn: row => `${row.FirstName} ${row.LastName}`, // Accessor function for the title
    id: "FirstName",
    header: "Name", // Header for the column
    cell: info => info.getValue(), // Cell renderer
    sortingFn: fuzzySort // Sorting function
  }
];

export const dataColumnsTeacher: ColumnDef<any, any>[] = [
  {
    accessorFn: row => row.Email, // Accessor function for the title
    id: "Email",
    header: "Email", // Header for the column
    cell: info => info.getValue(), // Cell renderer
    sortingFn: fuzzySort // Sorting function
  },
  {
    accessorFn: row => row.FirstName, // Accessor function for the title
    id: "FirstName",
    header: "First Name", // Header for the column
    cell: info => info.getValue(), // Cell renderer
    sortingFn: fuzzySort // Sorting function
  }
];

