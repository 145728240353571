import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../../Authenticator";
import useSWR from "swr";
import { deleteWithToken, fetcherWithToken } from "hooks/use-fetch-data";
import { Button, Text, Modal, DivComponent } from "components";
import {
  Container,
  Stats,
  Title,
  TD,
  ScoreCard,
  ScoreCategory,
  ScoreCardInfo,
  Section
} from "./styles";
import toast from "react-hot-toast";
import { ModalContent } from "./ModalContent";
import { FaRegCheckCircle } from "react-icons/fa";
import { Root, Module, Question } from "./types";
import { LeftSide, RightSide, Tile } from "Pages/Admin/Teacher/styles";
import { VscGraph } from "react-icons/vsc";
import { GrScorecard } from "react-icons/gr";
import { CiViewList } from "react-icons/ci";
import { Analysis } from "./Analysis";
import {
  groupQuestionsByDomain,
  groupQuestionsByLevel
} from "./Analysis/utils";
import { VscDebugRestartFrame } from "react-icons/vsc";
import { secondsToMinutesSeconds } from "Pages/helpers"

const TestReport = () => {
  const navigate = useNavigate();
  const userCtx = useUserContext();
  const params = useParams();
  const { state } = useLocation();
  const [selectedModule, setSelectedModule] = useState<Module>();
  const [filterModule, setFilteredModule] = useState<Module>();
  const [openModal, setOpenModal] = useState(false);
  const [questionNumber, setQuestionNumber] = useState<number | undefined>();
  const [selectedTab, setSelectedTab] = useState("score");
  const [domains, setDomains] = useState<string[]>([]);
  const [selectedDomainFilter, setSelectedDomainFilter] = useState("All");

  const reportUrl = (): string => {
    if (state?.student) {
      return `/api/v1/auth/class/${params.classID}/test/${params.testID}/report?student=${state.student}`;
    }

    return `/api/v1/auth/class/${params.classID}/test/${params.testID}/report`;
  };

  const { data, isLoading } = useSWR<Root>(reportUrl(), (url: string) =>
    fetcherWithToken<Root>(url, userCtx.token)
  );

  useEffect(() => {
    setSelectedModule(data?.report?.Modules[0]);
  }, [data]);

  useEffect(() => {
    const allDomains = [
      "All",
      ...new Set(selectedModule?.Questions?.map(q => q.Domain))
    ];
    setDomains(allDomains);
    setFilteredModule(selectedModule);
    setSelectedDomainFilter("All");
  }, [selectedModule]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const moduleID = data?.report.Modules.find(
      m => m.ModuleID === Number(event.target.value)
    );

    setSelectedModule(moduleID);
  };

  const setModalValues = (questionNumber: number) => {
    setQuestionNumber(questionNumber);
    setOpenModal(true);
  };

  const updateFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!selectedModule) {
      return;
    }

    const domain = event.target.value;
    setSelectedDomainFilter(domain);

    if (domain === "All") {
      setFilteredModule(selectedModule);
      return;
    }

    const filteredQuestions = selectedModule?.Questions.filter(
      question => question.Domain === domain
    );

    const totalQuestions = filteredQuestions?.length;
    const numberOfCorrectAnswers = filteredQuestions?.filter(
      question => question.AnsweredCorrectly
    ).length;
    const numberOfWrongAnswers = totalQuestions! - numberOfCorrectAnswers!;

    setFilteredModule({
      ModuleID: selectedModule.ModuleID,
      ModuleTitle: selectedModule.ModuleTitle,
      Questions: filteredQuestions!,
      TotalQuestions: totalQuestions!,
      NumberOfCorrectAnswers: numberOfCorrectAnswers!,
      NumberOfWrongAnswers: numberOfWrongAnswers,
      BlankAnswers: selectedModule.BlankAnswers
    });
  };

  const studentAnswer = (question: Question): string => {
    if (question.Type === "write_in") {
      return question.WriteInAnswer || "N/A";
    }

    if (!!question.UserAnswer) {
      return question.UserLetterAnswer;
    } else {
      return "N/A";
    }
  };

  const restartTest = async () => {
    const url = `/api/v1/auth/class/${params.classID}/test/${params.testID}/restart-test`;

    const res = await deleteWithToken(url, userCtx.token);

    if (res.status === 200) {
      navigate("/");
    }

    toast.success(`Test for ${data?.test.Title} can now be retaken`, {
      position: "bottom-left",
      duration: 10000,
      icon: <FaRegCheckCircle size={18} color="#154471" />
    });
  };

  const handleRestart = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to restart this test?\n\nThis will delete all past results and cannot be undone"
    );
    if (userConfirmed) {
      restartTest();
    }
  };

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        {selectedModule && questionNumber && (
          <ModalContent
            moduleID={selectedModule?.ModuleID}
            questionNumber={questionNumber}
            student={state?.student}
          />
        )}
      </Modal>
      <Container>
        <LeftSide>
          <Tile
            active={selectedTab === "score"}
            onClick={() => setSelectedTab("score")}
          >
            <Text size={18} mr={6}>
              Your Score
            </Text>
            <GrScorecard size={23} />
          </Tile>
          <Tile
            active={selectedTab === "analysis"}
            onClick={() => setSelectedTab("analysis")}
          >
            <Text size={18} mr={6}>
              Analysis
            </Text>
            <VscGraph size={23} />
          </Tile>
          <Tile
            active={selectedTab === "questionList"}
            onClick={() => setSelectedTab("questionList")}
          >
            <Text size={18} mr={6}>
              Questions
            </Text>
            <CiViewList size={23} />
          </Tile>
          <Tile onClick={handleRestart}>
            <Text size={18} mr={6}>
              Restart Test
            </Text>
            <VscDebugRestartFrame size={23} />
          </Tile>
        </LeftSide>
        <RightSide>
          {selectedTab === "analysis" && (
            <Section>
              <Title>
                <DivComponent alignItems>
                  <Text size={20} mr={12}>
                    Analysis by Module
                  </Text>
                  <select onChange={handleChange}>
                    {data?.report.Modules?.map(t => {
                      return (
                        <option value={t.ModuleID}>{t.ModuleTitle}</option>
                      );
                    })}
                    j
                  </select>
                </DivComponent>
                <Button
                  text="Filter Question List"
                  onClick={() => setSelectedTab("questionList")}
                />
              </Title>
              {selectedModule && (
                <Analysis
                  levelData={groupQuestionsByLevel(selectedModule)}
                  domainData={groupQuestionsByDomain(selectedModule)}
                />
              )}
            </Section>
          )}
          {selectedTab === "score" && !isLoading && (
            <Section>
              <Text size={32} mb={24} center>
                Your Practice Test Score for {data?.test.Title}
              </Text>
              <ScoreCard>
                <ScoreCardInfo>
                  <Text size={24} center mb={12}>
                    {data?.test.Title}
                  </Text>
                  <Text size={18} center mb={12} bold>
                    Your Total Score
                  </Text>
                  {data?.mathScore && data.englishScore && (
                    <>
                      <Text size={24} center color="#FBB23A" mb={2} bold>
                        {data?.mathScore + data?.englishScore}
                      </Text>
                      <Text size={10} center color="grey" mb={12} bold>
                        {data?.mathScore + data?.englishScore} of 1600
                      </Text>
                    </>
                  )}
                  <span />
                  <ScoreCategory>
                    <Text size={14} bold>
                      Reading and Writing Score
                    </Text>
                    <div>
                      <Text size={24} color="#FBB23A">
                        {data?.englishScore}
                      </Text>
                      <Text size={10} center color="grey" mb={12} bold>
                        {data?.englishScore} of 800
                      </Text>
                    </div>
                  </ScoreCategory>
                  <ScoreCategory>
                    <Text size={14} bold>
                      Math Score
                    </Text>
                    <div>
                      <Text size={24} color="#FBB23A">
                        {data?.mathScore}
                      </Text>
                      <Text size={10} center color="grey" mb={12} bold>
                        {data?.mathScore} of 800
                      </Text>
                    </div>
                  </ScoreCategory>
                </ScoreCardInfo>
              </ScoreCard>
            </Section>
          )}
          {selectedTab === "questionList" && (
            <Section>
              <Title>
                <DivComponent>
                  <Text size={20} mr={12}>
                    Details by Module
                  </Text>
                  <select
                    value={selectedModule?.ModuleID}
                    onChange={handleChange}
                  >
                    {data?.report.Modules?.map(t => (
                      <option value={t.ModuleID}>{t.ModuleTitle}</option>
                    ))}
                  </select>
                </DivComponent>
                <DivComponent>
                  <Text size={20} mr={12}>
                    Filter by Domain
                  </Text>
                  <select value={selectedDomainFilter} onChange={updateFilter}>
                    {domains.map(d => (
                      <option value={d}>{d}</option>
                    ))}
                  </select>
                </DivComponent>
              </Title>
              <Stats>
                <div>
                  <Text size={24}>{filterModule?.TotalQuestions}</Text>
                  <Text>Total Questions</Text>
                </div>
                <div>
                  <Text size={24}>{filterModule?.NumberOfCorrectAnswers}</Text>
                  <Text>Correct Answers</Text>
                </div>
                <div>
                  <Text size={24}>{filterModule?.NumberOfWrongAnswers}</Text>
                  <Text>Incorrect Answers</Text>
                </div>
                <div>
                  <Text size={24}>{filterModule?.BlankAnswers}</Text>
                  <Text>Blank Answers</Text>
                </div>
              </Stats>
              <table>
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Correct Answer</th>
                    <th>Your Answer</th>
                    <th>Level</th>
                    <th>Time Spent</th>
                    <th>Review</th>
                  </tr>
                </thead>
                <tbody>
                  {filterModule?.Questions.map(q => {
                    return (
                      <>
                        <tr key={q.QuestionID}>
                          <TD
                            correct={q.AnsweredCorrectly}
                          >
                            {q.QuestionNumber}
                          </TD>
                          <td>
                            {q.Type === "write_in"
                              ? q.CorrectAnswer
                              : q.CorrectLetterAnswer}
                          </td>
                          <td>{studentAnswer(q)}</td>
                          <td>{q.Level}</td>
                          <td>{secondsToMinutesSeconds(q.TimeSpent, true)}</td>
                          <td>
                            <Button
                              text="review"
                              onClick={() => setModalValues(q.QuestionNumber)}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </Section>
          )}
        </RightSide>
      </Container>
    </>
  );
};

export default TestReport;
