import {DashboardI} from "Pages/Dashboard/types";

export const getFirstModule = (modules?: DashboardI["Modules"]) => {
  const sortByOrder = modules?.sort(m => m.Order)

  return sortByOrder?.find(m => {
    if (m.UserModule.Finished) {
      return false
    }

    return m.UserModule.Finished === false
  })
}

export const secondsToMinutesSeconds = (
  s?: number,
  dontShowEmptyMins = false
) => {
  if (!s) {
    return;
  }

  let minutes = Math.floor(s / 60);
  let seconds = s % 60;

  if (dontShowEmptyMins) {
    if (minutes === 0) {
      return `${seconds} seconds`;
    }

    return `${minutes} mins : ${seconds} seconds`;
  }

  return `${minutes} mins : ${seconds} seconds`;
};
