import React, { useEffect, useState } from "react";
import {
  SectionContainer,
  ClassHeader,
  StepContainer,
  UpdateClassContainer
} from "./styles";
import { Button } from "components/Button";
import { Class, School, Student, Teacher, Test } from "../types";
import { postWithToken, updateWithToken } from "hooks/use-fetch-data";
import { useUserContext } from "Authenticator";
import {
  GenericTable,
  Text,
  Input,
  Step,
  Stepper,
  Modal,
  UserTable,
  DivComponent
} from "components";
import {
  steps,
  dataColumnsTests,
  dataColumnsStudents,
  dataColumnsTeacher
} from "./types";

export const ClassComponent: React.FC<{
  classes?: Class[];
  students?: Student[];
  teachers?: Teacher[];
  tests?: Test[];
  selectedSchool?: School;
  refreshDashboard: (schoolSlug?: School) => Promise<void>;
}> = ({
  classes,
  students,
  tests,
  selectedSchool,
  refreshDashboard,
  teachers
}) => {
  const userCtx = useUserContext();

  const [createClass, setCreateClass] = useState(false);
  const [studentModal, setStudentModal] = useState(false);
  const [testModal, setTestModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState<Class>();
  const [testsToAdd, setTestsToAdd] = useState<number[]>([]);

  const [formData, setFormData] = useState<{
    className: string;
    students: number[];
    teachers: number[];
    tests: number[];
    school?: number;
  }>({
    className: "",
    students: [],
    teachers: [],
    tests: [],
    school: undefined
  });

  useEffect(() => {
    setFormData({ ...formData, school: selectedSchool?.SchoolID });
  }, [selectedSchool]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, className: e.target.value });
  };

  const updateForm = (id: number, role: "students" | "teachers") => {
    const found = formData[role].find(s => s === id);

    if (found) {
      const newState = formData[role].filter(s => s !== id);
      setFormData({
        ...formData,
        [role]: newState
      });
    } else {
      if (role === "teachers") {
        setFormData({
          ...formData,
          teachers: [id]
        });
      } else {
        setFormData({
          ...formData,
          [role]: [...formData[role], id]
        });
      }
    }
  };

  const updateTests = (id: number) => {
    const found = formData.tests.find(t => t === id);
    if (found) {
      const newState = formData.tests.filter(t => t !== id);
      setFormData({
        ...formData,
        tests: newState
      });
    } else {
      setFormData({
        ...formData,
        tests: [...formData.tests, id]
      });
    }
  };

  const saveClass = async () => {
    const res = await postWithToken(
      `/api/v1/auth/admin/dashboard/save-class`,
      userCtx.token,
      {
        ...formData
      }
    );

    if (res.status === 201) {
      refreshDashboard(selectedSchool);
      setCreateClass(false);
    }
  };

  const [studentsToAdd, setStudentsToAdd] = useState<number[]>([]);

  const addToStudents = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const studentNumber = Number(value);

    const isExisting = studentsToAdd.includes(studentNumber);

    if (isExisting) {
      const updatedStudents = studentsToAdd.filter(
        num => num !== studentNumber
      );
      setStudentsToAdd(updatedStudents);
    } else {
      setStudentsToAdd([...studentsToAdd, studentNumber]);
    }
  };

  const addStudents = async () => {
    const res = await postWithToken(
      "/api/v1/auth/admin/dashboard/save-user-to-class",
      userCtx.token,
      {
        studentsToAdd,
        classID: selectedClass?.ClassID
      }
    );

    if (res.status === 201) {
      refreshDashboard(selectedSchool);
      setStudentModal(false);
      setStudentsToAdd([]);
    }
  };

  const addTestsToClass = async () => {
    await updateWithToken(
      `/api/v1/auth/admin/dashboard/update-class-tests`,
      userCtx.token,
      {
        tests: testsToAdd,
        classID: selectedClass?.ClassID
      }
    );

    setTestModal(false);
    refreshDashboard();
  };

  const toggleTest = (testId: number) => {
    setTestsToAdd(prevTests => {
      // Check if the testId already exists in the array
      if (prevTests.includes(testId)) {
        // Remove the testId if it already exists
        return prevTests.filter(id => id !== testId);
      } else {
        // Add the testId if it doesn't exist
        return [...prevTests, testId];
      }
    });
  };

  const teacherEvent = (id: number) => {
    updateForm(id, "teachers");
  };

  const studentEvent = (id: number) => {
    updateForm(id, "students");
  };

  return (
    <>
      <Modal open={studentModal} onClose={() => setStudentModal(false)}>
        <Text size={32} center>
          Add Students to {selectedClass?.Name}
        </Text>
        <UpdateClassContainer>
          <UserTable
            students={students}
            checkBox
            checkedStudents={studentsToAdd}
            checkBoxClick={addToStudents}
          />
        </UpdateClassContainer>
        <Button text="Add Students" onClick={addStudents} floatRight />
      </Modal>
      <Modal open={testModal} onClose={() => setTestModal(false)}>
        <Text size={32} center>
          Add Tests to {selectedClass?.Name}
        </Text>
        <UpdateClassContainer>
          <GenericTable
            data={tests!}
            columns={dataColumnsTests}
            checkBox
            checkedItems={testsToAdd}
            checkBoxClick={id => toggleTest(id)}
            idKey="TestID"
          />
        </UpdateClassContainer>
        <Button text="Submit" onClick={addTestsToClass} floatRight />
      </Modal>
      <SectionContainer>
        {createClass && (
          <>
            <Stepper steps={steps}>
              <Step s={1}>
                <StepContainer>
                  <Text size={14} bold mb={12}>
                    Class Name
                  </Text>
                  <Input placeholder="Class Name..." onChange={handleChange} />
                </StepContainer>
              </Step>
              <Step s={2}>
                <StepContainer>
                  <Text size={14} bold mb={12}>
                    Assign Teachers To {formData.className}
                  </Text>
                  <GenericTable
                    data={teachers!}
                    columns={dataColumnsTeacher}
                    idKey="UserID"
                    checkBox
                    checkBoxClick={e => teacherEvent(e)}
                    checkedItems={formData.teachers}
                  />
                </StepContainer>
              </Step>
              <Step s={3}>
                <StepContainer>
                  <Text size={14} bold mb={12}>
                    Assign Students To Class
                  </Text>
                  <GenericTable
                    data={students!}
                    columns={dataColumnsStudents}
                    idKey="UserID"
                    checkBox
                    checkBoxClick={e => studentEvent(e)}
                    checkedItems={formData.students}
                  />
                </StepContainer>
              </Step>
              <Step s={4} next={saveClass}>
                <StepContainer>
                  <Text size={14} bold mb={12}>
                    Assing Test to Class
                  </Text>
                  <GenericTable
                    data={tests!}
                    columns={dataColumnsTests}
                    checkBox
                    checkedItems={formData.tests}
                    checkBoxClick={e => updateTests(e)}
                    idKey="TestID"
                  />
                </StepContainer>
              </Step>
            </Stepper>
          </>
        )}
        {!createClass && (
          <>
            <ClassHeader>
              <Text size={24}>Class List for {selectedSchool?.Name}</Text>
              <Button
                text="Create a Class"
                onClick={() => setCreateClass(true)}
              />
            </ClassHeader>
            {classes?.map(c => {
              return (
                <div
                  style={{
                    marginBottom: "30px",
                    borderBottom: "1px solid #FBB23A",
                    paddingBottom: "24px"
                  }}
                >
                  <Text mb={12} size={12}>
                    Class: {c.Name}
                  </Text>
                  <Text mb={12} size={12}>
                    Teacher:{" "}
                    {c?.Teachers?.map(t => `${t.FirstName} ${t.LastName}`)}
                  </Text>
                  <DivComponent>
                    <Text size={12} mr={5}>
                      Tests:
                    </Text>
                    {c.Tests.map((t, index) => (
                      <React.Fragment key={index}>
                        <Text mr={4}>{t.Title}</Text>
                        {index < c.Tests.length - 1 && (
                          <Text size={12} mr={4}>
                            and
                          </Text>
                        )}
                      </React.Fragment>
                    ))}
                  </DivComponent>
                  <GenericTable
                    data={c.Students!}
                    columns={dataColumnsStudents}
                    idKey="UserID"
                  />
                  <DivComponent>
                    <Button
                      onClick={() => {
                        setSelectedClass(c);
                        setStudentModal(true);
                      }}
                      text={`Add students`}
                      floatRight
                    />
                    <Button
                      onClick={() => {
                        setSelectedClass(c);
                        setTestModal(true);
                      }}
                      ml={10}
                      text={`Add Tests`}
                    />
                  </DivComponent>
                </div>
              );
            })}
          </>
        )}
      </SectionContainer>
    </>
  );
};
