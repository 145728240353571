import React from "react";
import {
  Container,
  Heading,
  SubHeading,
  Paragraph,
  List,
  ListItem,
  Table,
  TableHead,
  TableCell,
  ExamplesSection,
} from "./styles";

export const MathSkills = () => {
  return (
    <Container>
      <Heading>Math Skills Directions</Heading>
      <Paragraph>
        The questions in this section address a number of important math skills.
      </Paragraph>
      <Paragraph>
        Use of a calculator is permitted for all questions. A reference sheet,
        calculator, and these directions can be accessed throughout the test.
      </Paragraph>
      <Paragraph>Unless otherwise indicated:</Paragraph>
      <List>
        <ListItem>All variables and expressions represent real numbers</ListItem>
        <ListItem>Figures provided are drawn to scale.</ListItem>
        <ListItem>All figures lie in a plane</ListItem>
        <ListItem>
          The domain of a given function <em>f</em> is the set of all real
          numbers <em>x</em> for which <em>f(x)</em> is a real number.
        </ListItem>
      </List>
      <SubHeading>Multiple-choice Questions</SubHeading>
      <Paragraph>
        Solve each problem and choose the correct answer from the choices
        provided. Each multiple-choice question has a single correct answer.
      </Paragraph>
      <SubHeading>Student-produced Response Questions</SubHeading>
      <Paragraph>Solve each problem and enter your answer as described below:</Paragraph>
      <List>
        <ListItem>
          If you find <strong>more than one correct answer</strong>, enter only
          one answer.
        </ListItem>
        <ListItem>
          You can enter up to 5 characters for a <strong>positive answer</strong>{" "}
          and up to 6 characters (including the negative sign) for a{" "}
          <strong>negative answer</strong>.
        </ListItem>
        <ListItem>
          If your answer is a <strong>fraction</strong> that doesn't fit in the
          provided space, enter the decimal equivalent.
        </ListItem>
        <ListItem>
          If your answer is a <strong>decimal</strong> that doesn't fit in the
          provided space, enter it by truncating or rounding at the fourth
          digit.
        </ListItem>
        <ListItem>
          If your answer is a <strong>mixed number</strong> (such as{" "}
          <sup>3</sup>&frasl;<sub>1</sub>), enter it as an improper fraction (
          <sup>7</sup>&frasl;<sub>2</sub>) or its decimal equivalent (3.5).
        </ListItem>
        <ListItem>
          Don't enter symbols such as percent sign, comma, or dollar sign.
        </ListItem>
      </List>
      <ExamplesSection>
        <SubHeading>Examples</SubHeading>
        <Table>
          <thead>
            <tr>
              <TableHead>Answer</TableHead>
              <TableHead>Acceptable way to enter answer</TableHead>
              <TableHead>Unacceptable: will NOT receive credit</TableHead>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TableCell>3.5</TableCell>
              <TableCell>
                3.5<br />
                3.50<br />
                7/2
              </TableCell>
              <TableCell>
                31/2<br />
                3 1/2
              </TableCell>
            </tr>
            <tr>
              <TableCell>2/3</TableCell>
              <TableCell>
                2/3<br />
                .6666<br />
                .6667
              </TableCell>
              <TableCell>
                0.66<br />
                .66<br />
                0.67<br />
                .67
              </TableCell>
            </tr>
            <tr>
              <TableCell>-1/3</TableCell>
              <TableCell>
                -1/3<br />
                -.3333<br />
                -.333
              </TableCell>
              <TableCell>
                -.33<br />
                -0.33
              </TableCell>
            </tr>
          </tbody>
        </Table>
      </ExamplesSection>
    </Container>
  );
};

export default MathSkills;

