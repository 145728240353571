import styled from "styled-components";

export const TextComponent = styled.p<{
  size?: number;
  bold?: boolean;
  center?: boolean;
  mb?: number;
  mt?: number;
  ml?: number;
  mr?: number;
  hover?: boolean;
  color?: string;
  underline?: boolean;
  background?: string;
}>`
  margin: 0;
  color: black;

  ${({ bold }) =>
    bold &&
    `
    font-weight: 700;
    `}

  ${({ size }) =>
    size &&
    `
    font-size:  ${size}px;
    `}

  ${({ mb }) =>
    mb &&
    `
    margin-bottom:  ${mb}px;
    `}

  ${({ mt }) =>
    mt &&
    `
    margin-top:  ${mt}px;
    `}

  ${({ ml }) =>
    ml &&
    `
    margin-left:  ${ml}px;
    `}

  ${({ mr }) =>
    mr &&
    `
    margin-right:  ${mr}px;
    `}

  ${({ color }) =>
    color &&
    `
    color:  ${color};
    `}

  ${({ background }) =>
    background &&
    `
    background:  ${background};
    padding: 12px;
    `}

  ${({ center }) =>
    center &&
    `
    text-align:  center;
    `}

  ${({ underline }) =>
    underline &&
    `
    text-decoration: underline;
    `}

  &:hover {

    ${({ hover, theme }) =>
      hover &&
      `
      cursor: pointer;
      color:  ${theme.colors.primary1};
      `}
  }
`;
